<template>
  <PWAInstallation v-if="showPrompt" @install-pwa="install" />
  <Modal :isDisplayed="showPopup" @close-modal="showPopup = false">
    <img
      class="instruction-image"
      src="@/assets/images/iphone_download_manual.png"
      alt="Instructions d'installation"
    />
  </Modal>
</template>
<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
} from 'vue';

import Modal from '@/components/common/Modal.vue';
import rootCSSVariables from '@/utils/rootCSSVariables.utils';

import PWAInstallation from './Installation.vue';

export default defineComponent({
  name: 'PWABanner',
  components: {
    PWAInstallation,
    Modal,
  },
  setup() {
    const showPrompt = ref(false);
    const showPopup = ref(false);
    const deferredPrompt = ref(null);

    const iOS = computed(() => /(iPad|iPhone|iPod)/g.test(navigator.userAgent));

    watch(showPrompt, (value) => {
      rootCSSVariables.mutate('--pwa-prompt-height', value ? '2.5rem' : '0rem');
    });

    const install = () => {
      if (iOS.value) {
        showPopup.value = true;
        
        return;
      }

      showPrompt.value = false;
      // Show the install prompt
      deferredPrompt.value.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.value.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // console.log('User accepted the install prompt');
        } else {
          // console.log('User dismissed the install prompt');
        }
      });
    };

    // onMounted(() => {
    //   // Created
    // });
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt.value = e;
      // Update UI notify the user they can install the PWA
      showPrompt.value = true;
    });

    if (iOS.value) {
      // Check if we have are in the PWA or not.
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        showPrompt.value = true;
      }
    }

    return {
      showPrompt,
      showPopup,
      install,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";

.instruction-image {
  height: auto;
  width: 100%;
}
</style>
