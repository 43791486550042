import { ref, onMounted, onUnmounted } from 'vue';

const useInterval = (callback, delay, executeDirectly = false) => {
  const timer = ref(null);

  onMounted(() => {
    timer.value = setInterval(callback, delay);

    if (executeDirectly) {
      callback();
    }
  });

  onUnmounted(() => {
    clearInterval(timer.value);
  });
};

export default useInterval;
