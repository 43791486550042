<script setup>
import {
  computed,
  defineProps,
  toRefs,
  watch,
  onMounted,
  onUnmounted, 
} from 'vue';

import CompaniesRefreshProvider from '@/CompaniesRefresh.vue';
import Loader from '@/components/common/Loader.vue';
import PAWUpdateApp from '@/components/pwa/UpdateApp.vue';
import PWABanner from '@/components/pwa/PWABanner.vue';
import Header from '@/components/common/Header.vue';
import useAuthenticatedUser from '@/composables/useAuthenticatedUser';
import NavigationMenu from './NavigationMenu.vue';
import useWindowSize from '@/composables/useWindowSize';
import rootCSSVariables from '@/utils/rootCSSVariables.utils';

const props = defineProps({
  loading: { type: Boolean, required: false },
  containerClassname: { type: [String, Object], required: false },
  hideHeader: { type: Boolean, required: false },
});

const { hideHeader } = toRefs(props);

const { isConnected } = useAuthenticatedUser();
const { isIPadOrGreater } = useWindowSize();

const showHeader = computed(() => !hideHeader.value || isIPadOrGreater.value);

watch(showHeader, (value) => {
  rootCSSVariables.mutate('--header-height', value ? '7rem' : '0rem');
});

const resizeHandler = () => {
  const vh = window.innerHeight * 0.01;

  rootCSSVariables.mutate('--vh', `${vh}px`);
};

onMounted(() => {
  resizeHandler();
  window.addEventListener('resize', resizeHandler);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandler);
});
</script>

<template>
  <div id="wrapper" v-if="isConnected">
    <CompaniesRefreshProvider>
      <PWABanner />
      <PAWUpdateApp v-if="showHeader" />
      <Header v-if="showHeader" />
      <div class="page">
        <NavigationMenu />
        <div v-if="!loading" :class="['container', containerClassname]">
          <slot></slot>
        </div>
        <div v-else class="container">
          <Loader />
        </div>
      </div>
    </CompaniesRefreshProvider>
  </div>
  <div id="wrapper" v-else>
    <template v-if="!loading">
      <slot></slot>
    </template>
    <div v-else class="container">
      <Loader />
    </div>
  </div>
  <div class="safe-area" />
</template>

<style lang="scss" scoped>
@import '@/assets/styles/common/variables.scss';
@import '@/assets/styles/common/mixins.scss';

.safe-area {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: env(safe-area-inset-bottom, 0rem);
  background-color: #fff;
}

.page {
  display: flex;
}

.container {
  --viewport-height: 100vh;
  --viewport-height: calc(var(--vh, 1vh) * 100);

  @supports (height: 100dvh) {
    --viewport-height: 100dvh;
  }

  
  min-height: calc(
    var(--viewport-height)
    - var(--bottom-navigation-height)
    - var(--header-height)
    - var(--pwa-prompt-height)
    - env(safe-area-inset-bottom, 0rem)
  );

  width: 100%;
  
  padding-top: 5rem;
  padding-bottom: calc(
    var(--bottom-navigation-height)
    + var(--header-height)
    + var(--pwa-prompt-height)
    + env(safe-area-inset-bottom, 0rem)
  );
}
</style>
