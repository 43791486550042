export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  created() {
    document.addEventListener(
      'swUpdated', this.showRefreshUI, { once: true },
    );
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) {
            return;
          }
          
          this.refreshing = true;
          window.location.reload();
        },
      );
    }
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;

      if (!this.registration?.waiting) {
        return;
      }

      this.registration.waiting.postMessage('skipWaiting');
    },
  },
};
