<template>
  <select
    class="lowco-textbox"
    v-if="companies.length"
    :value="currentCompanyId"
    @change="handleCompanyChange"
  >
    <option
      v-for="company in sortedCompanies"
      :key="company.id"
      :value="company.id"
    >{{ company.name }}</option>
  </select>
</template>

<script>
import {
  defineComponent,
  computed,
} from 'vue';

import useCurrentCompany from '../../composables/useCurrentCompany';

export default defineComponent({
  name: 'EnterpriseDropdown',
  setup() {
    const {
      companies,
      currentCompanyId,
      setCurrentCompanyId,
    } = useCurrentCompany();

    const sortedCompanies = computed(() => companies.value
      .slice()
      .sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA > nameB) {
          return 1;
        }

        if (nameA < nameB) {
          return -1;
        }

        return 0;
      }));

    const handleCompanyChange = (event) => {
      const { value } = event.target;

      setCurrentCompanyId(value);
    };

    return {
      companies,
      currentCompanyId,
      sortedCompanies,
      handleCompanyChange,
    };
  },
});
</script>

<style lang="scss" scoped>
p {
  color: white;
}
</style>
