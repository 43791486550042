<template>
  <div id="pwa-installation">
    <button @click="$emit('install-pwa')" class="pointer">Télécharger l'application</button>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/common/variables.scss";

div {
  height: var(--pwa-prompt-height);
  color: $soft-white;
  background-color: $dark-green;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  button {
    text-decoration: underline;
    color: $white !important;
  }
}
</style>
