<template>
  <div class="modal" v-if="isDisplayed">
    <transition name="fade">
      <div :class="['content', contentClassname]" ref="modal">
        <div class="header">
          <h3 class="tal">{{ title }}</h3>
          <button v-show="!isFreezed" class="close-button" @click="closeModal">
            <FontAwesomeIcon class="close-icon" :icon="faTimes" />
          </button>
        </div>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'Modal',
  components: {
    FontAwesomeIcon,
  },
  props: {
    isDisplayed: Boolean,
    isFreezed: Boolean,
    title: String,
    contentClassname: String,
  },
  computed: {
    faTimes: () => faTimes,
  },
  watch: {
    isDisplayed(value) {
      document.body.style.overflow = value ? 'hidden' : '';
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    handleOutsideClick(event) {
      if (!this.isDisplayed) {
        return;
      }

      if (this.$refs.modal && !this.$refs.modal.contains(event.target)) {
        this.closeModal();
      }
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  },
  unmounted() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.modal {
  z-index: 9999999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include spacing-children('horizontal', 2rem);

    margin-bottom: 2rem;
  }

  .close-button {
    justify-self: flex-end;
  }

  .close-icon {
    font-size: 2rem;
  }

  .content {
    max-height: calc(100vh - 10rem);
    max-width: 70rem;
    width: 98%;
    overflow-y: auto;
    overscroll-behavior: contain;
    background-color: white;
    border-radius: 5px;
    padding: 20px;

    @include lg {
      max-width: 80rem;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: max-height 0.2s ease-out;
}
.fade-enter,
.fade-leave-to {
  max-height: 0;
}
</style>
