import config from '@/config';

import httpService from '@/services/http.service';

const BASE_URL = config.lowcoApiUrl;
const APP_CONTEXT = 'BACKOFFICE';

const authenticate = async (credentials) => httpService
  .post(`${BASE_URL}/auth/login`, { ...credentials, context: APP_CONTEXT });

const logout = async () => httpService
  .post(`${BASE_URL}/auth/logout`, {});

export default {
  authenticate,
  logout,
};
