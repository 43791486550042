const useDebounce = (callback, debounceDelay = 250) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), debounceDelay);
  };
};

export default useDebounce;
