import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from 'vue';

import useDebounce from './useDebounce';

const IPHONE_5_MIN_WIDTH = 320;
const IPHONE_6_MIN_WIDTH = 375;
const XS_MIN_WIDTH = 576;
const SM_MIN_WIDTH = 768;
const MD_MIN_WIDTH = 992;
const IPAD_MIN_WIDTH = 1024;
const LG_MIN_WIDTH = 1250;

const useWindowSize = (debounceDelay = 150) => {
  const windowSize = ref({ width: 0, height: 0 });
  const debouncedSetWindowSize = useDebounce((widthValue, heightValue) => {
    windowSize.value = { width: widthValue, height: heightValue };
  }, debounceDelay);

  const isIphone5OrGreater = computed(() => windowSize.value.width >= IPHONE_5_MIN_WIDTH);
  const isIphone6OrGreater = computed(() => windowSize.value.width >= IPHONE_6_MIN_WIDTH);
  const isXSOrGreater = computed(() => windowSize.value.width >= XS_MIN_WIDTH);
  const isSMOrGreater = computed(() => windowSize.value.width >= SM_MIN_WIDTH);
  const isMDOrGreater = computed(() => windowSize.value.width >= MD_MIN_WIDTH);
  const isIPadOrGreater = computed(() => windowSize.value.width >= IPAD_MIN_WIDTH);
  const isLGOrGreater = computed(() => windowSize.value.width >= LG_MIN_WIDTH);

  const resizeHandler = () => {
    const { innerWidth, innerHeight } = window;
    debouncedSetWindowSize(innerWidth, innerHeight);
  };

  onMounted(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler);
  });

  return {
    windowWidth: windowSize.value.width,
    windowHeight: windowSize.value.height,
    isIphone5OrGreater,
    isIphone6OrGreater,
    isXSOrGreater,
    isSMOrGreater,
    isMDOrGreater,
    isIPadOrGreater,
    isLGOrGreater,
  };
};

export default useWindowSize;
