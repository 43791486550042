<script setup>
import { computed } from 'vue';
import {
  faHome,
  faUser,
  faBookOpen,
  faStore,
  faPlusCircle,
  faTags,
  faQrcode,
  faMegaphone,
} from '@fortawesome/pro-solid-svg-icons';

import useWindowSize from '@/composables/useWindowSize';
import useAuthenticatedUser from '@/composables/useAuthenticatedUser';
import routesConstants from '@/constants/routes.constants';

import Mobile from './MobileMenu.vue';
import Desktop from './DesktopMenu.vue';
import useCompanyOffers from '@/composables/useCompanyOffers';

const { isIPadOrGreater } = useWindowSize();
const { isAdmin } = useAuthenticatedUser();
const { canUseAnnouncements } = useCompanyOffers();

const menuItems = computed(() => {
  const items = [
    {
      to: routesConstants.HOME,
      icon: faHome,
      title: 'components.menu.home',
      requiresAdminAccess: false,
      isExtra: false,
      isShown: true,
    },
    {
      to: routesConstants.LOYALTY_CARD_SCANNER,
      icon: faQrcode,
      title: 'components.menu.scanLoyalty',
      requiresAdminAccess: false,
      isExtra: false,
      isShown: true,
    },
    {
      to: routesConstants.CATALOG,
      icon: faBookOpen,
      title: 'components.menu.catalog',
      requiresAdminAccess: false,
      isExtra: false,
      isShown: true,
    },
    {
      to: routesConstants.OFFERS,
      icon: faTags,
      title: 'components.menu.offers',
      requiresAdminAccess: false,
      isExtra: canUseAnnouncements.value,
      isShown: true,
    },
    {
      to: routesConstants.ANNOUNCEMENTS,
      icon: faMegaphone,
      title: 'components.menu.announcements',
      requiresAdminAccess: false,
      isExtra: false,
      isShown: canUseAnnouncements.value,
    },
    {
      to: routesConstants.PROFILE,
      icon: faUser,
      title: 'components.menu.profile',
      requiresAdminAccess: false,
      isExtra: true,
      isShown: true,
    },
    {
      to: routesConstants.NEW_ENTERPRISE,
      icon: faPlusCircle,
      title: 'components.menu.addEnterprise',
      requiresAdminAccess: false,
      isExtra: true,
      isShown: true,
    },
    {
      to: routesConstants.ADMIN_ENTERPRISES,
      icon: faStore,
      title: 'components.menu.enterprises',
      requiresAdminAccess: true,
      isExtra: true,
      isShown: true,
    },
  ];

  const filteringPredicate = (item) => {
    const { requiresAdminAccess } = item;

    if (!item.isShown) {
      return false;
    }

    if (requiresAdminAccess) {
      return isAdmin.value;
    }

    return true;
  };

  return items.filter(filteringPredicate);
});
</script>

<template>
  <Desktop v-if="isIPadOrGreater" :items="menuItems" />
  <Mobile v-else :items="menuItems" />
</template>
