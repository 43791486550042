<template>
  <BottomNavigation
    class="bottom-menu"
    :options="navMenuItems"
    :value="currentTab"
    foregroundColor="#ddefe2"
    badgeColor="#02474d"
    @update="handleNavigationChange"
  >
    <template v-slot:icon="{ props }">
      <FontAwesomeIcon :icon="props.icon" />
    </template>
  </BottomNavigation>

  <div :class="['drawer', { 'drawer--is-open': currentTab === MORE_TAB }]">
    <nav class="drawer__nav">
      <div class="nav__top">
        <EnterpriseDropdown />
      </div>

      <ul class="nav__list">
        <li v-for="item in items" :key="item.to" class="nav__item">
          <router-link :to="item.to" @click="handleLinkClick" v-slot="{ isActive }">
            <span :class="{ active: isActive }">
              <FontAwesomeIcon class="mr-2" :icon="item.icon" />
              {{ t(item.title) }}
            </span>
          </router-link>
        </li>
      </ul>

      <div class="nav__bottom">
        <Button link-like class="link" @buttonClick="signOut">{{ t('components.menu.signOut') }}</Button>
      </div>
    </nav>
  </div>
</template>
<script>
import { computed, defineComponent, ref, toRefs, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';

import routesConstants from '@/constants/routes.constants';
import Button from '@/components/common/Button.vue';

import BottomNavigation from './BottomNavigation.vue';
import EnterpriseDropdown from '../admin/EnterpriseDropdown.vue';
import useAuthentication from '@/composables/useAuthentication';

const MORE_TAB = 'more';

export default defineComponent({
  name: 'MobileMenu',
  components: {
    FontAwesomeIcon,
    BottomNavigation,
    EnterpriseDropdown,
    Button,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { items } = toRefs(props);
    const router = useRouter();
    const route = useRoute();

    const { t } = useI18n();

    const { signOut } = useAuthentication();

    const currentTab = ref(route.path);

    const navMenuItems = computed(() => ([
      ...items.value
        .filter((i) => !i.isExtra)
        .map((i) => ({
          ...i,
          id: i.to,
          title: t(i.title),
        })),
      {
        id: MORE_TAB,
        icon: faEllipsisH,
        title: t('components.menu.more'),
      },
    ]));

    watchEffect(() => {
      document.body.style.overflow = currentTab.value === MORE_TAB ? 'hidden' : '';
    });

    watchEffect(() => {
      if (route.path === '/') {
        return;
      }

      currentTab.value = route.path;
    });

    const handleNavigationChange = (value) => {
      currentTab.value = value;

      if (value !== MORE_TAB) {
        router.push(value);
      }
    };

    const handleLinkClick = (event) => {
      const { pathname: newRoute } = event.view.location;

      if (newRoute === route.path) {
        currentTab.value = newRoute;
      }
    };

    return {
      t,
      routesConstants,
      currentTab,
      navMenuItems,
      handleNavigationChange,
      signOut,
      handleLinkClick,
      MORE_TAB,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common/variables.scss';
@import '../../assets/styles/common/mixins.scss';

.bottom-menu {
  @include ipad {
    display: none;
  }
}

.drawer {
  --viewport-height: 100vh;
  --viewport-height: calc(var(--vh, 1vh) * 100);

  @supports (height: 100dvh) {
    --viewport-height: 100dvh;
  }

  position: fixed;
  right: 0;
  top: 0;

  height: calc(
    var(--viewport-height)
    - var(--bottom-navigation-height)
    - env(safe-area-inset-bottom, 0rem)
  );
  
  width: 100%;
  transition: all 0.3s ease;
  transform: translateX(100%);
  background-color: #fff;
  z-index: 999999;

  &--is-open {
    transform: translateX(0);
  }

  &__nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 8rem 4rem;
  }

  @include ipad {
    display: none;
  }
}

.nav {
  &__top {
    display: flex;
    justify-content: center;
    align-items: center;

    .lowco-textbox {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    font-size: 2rem;

    a {
      font-size: inherit;
    }

    .active {
      color: $main-color;
    }
  }

  &__item + &__item {
    margin-top: 2.5rem;
  }
}
</style>
