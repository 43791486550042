<template>
  <slot></slot>
</template>
<script>
import { defineComponent, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import useCurrentCompany from './composables/useCurrentCompany';
import useInterval from './composables/useInterval';

const INTERVAL_TIME_IN_MINUTES = 5;
export default defineComponent({
  name: 'CompaniesRefresh',
  setup() {
    const toast = useToast();
    const { t } = useI18n();

    const {
      fetchCompanies,
      companies,
      currentCompanyId,
      setCurrentCompanyId,
    } = useCurrentCompany();
    useInterval(async () => {
      try {
        await fetchCompanies();
      } catch (err) {
        const [error] = err;

        toast.error(t(error));
      }
    }, INTERVAL_TIME_IN_MINUTES * 60000, true);

    const unwatch = watch(companies, (value) => {
      if (!value?.length) {
        return;
      }

      if (currentCompanyId.value) {
        unwatch();
        
        return;
      }

      const [firstCompany] = value;
      setCurrentCompanyId(firstCompany.id);

      unwatch();
    });
  },
});
</script>
