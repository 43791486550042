<template>
  <nav class="menu" :style="{ top: menuTop }">
    <ul>
      <li v-for="item in items" :key="item.to">
        <router-link :to="item.to">
          <FontAwesomeIcon :icon="item.icon" />
          <span> {{ t(item.title) }} </span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'DesktopMenu',
  components: {
    FontAwesomeIcon,
  },
  props: {
    hasAlert: Boolean,
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  data() {
    return {
      menuTop: '70px',
    };
  },
  mounted() {
    window.addEventListener('scroll', this.menuTopScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.menuTopScroll);
  },
  watch: {
    enterprise() {
      this.menuTopScroll();
    },
  },
  methods: {
    menuTopScroll() {
      let headerHeight = 70;
      const alertHeight = 46;

      if (this.enterprise && !this.enterprise.mollieCustomerId) {
        headerHeight += alertHeight;
      }

      let menuTopValue = headerHeight - window.pageYOffset;

      if (menuTopValue < 0) {
        menuTopValue = 0;
      }

      this.menuTop = `${menuTopValue}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

.menu {
  width: 100%;
  max-width: 230px;
  z-index: 0;
  background-color: $dark-white;

  display: none;

  @include ipad {
    display: block;
  }

  ul {
    margin-top: 2rem;
    li {
      a {
        color: $dark-green;
        font-size: 2rem;
        padding: 1rem;
        padding-left: 2.3rem;
        width: 100%;

        &:hover {
          color: $main-color;
        }

        svg {
          width: 2rem !important;
          font-size: 1.8rem;
          margin-right: 1rem;
        }

        &.router-link-active {
          color: $main-color;
        }
      }
    }
  }
}
</style>
