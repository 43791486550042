<template>
  <div>
    <button
      :type="type || 'button'" 
      :class="[buttonClasses, buttonClass, { [$style.block]: block }]" 
      @click="click"
    >
      <FontAwesomeIcon v-if="icon" :icon="icon" :class="[$style.icon, iconClass]" />
      <slot v-if="!isLoading" />
      <FontAwesomeIcon v-if="isLoading" :class="$style.loading" :icon="faSpinnerThird" />
      <template v-if="!isLoading">{{ text }}</template>
    </button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'Button',
  emits: ['buttonClick'],
  components: {
    FontAwesomeIcon,
  },
  props: {
    type: String,
    text: String,
    disabled: Boolean,
    alternate: Boolean,
    isLoading: Boolean,
    small: Boolean,
    extraSmall: Boolean,
    extraSmall2: Boolean,
    linkLike: Boolean,
    linkLikeUnderlined: Boolean,
    buttonClass: [String, Array],
    isDanger: Boolean,
    warn: Boolean,
    block: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: [Array, Object],
      required: false,
    },
    iconClass: {
      type: [String, Array],
      required: false,
    },
  },
  computed: {
    faSpinnerThird: () => faSpinnerThird,
    buttonClasses() {
      return {
        'lowco-button': true,
        'lowco-button--disabled': this.disabled || this.isLoading,
        'lowco-button--alternate': this.alternate,
        'lowco-button--small': this.small,
        'lowco-button--extra-small': this.extraSmall,
        'lowco-button--link': this.linkLike,
        'lowco-button--link-underline': this.linkLikeUnderlined,
        'lowco-button--error': this.isDanger,
        'lowco-button--warn': this.warn,
      };
    },
  },
  methods: {
    click() {
      if (!this.disabled && !this.isLoading) {
        this.$emit('buttonClick');
      }
    },
  },
};
</script>

<style lang="scss" module>
.image {
  height: 1.6rem;
  width: auto;
}

.icon {
  font-size: inherit;
  margin-right: 1rem;
}

.block {
  width: 100%;
}

.loading {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 0.8s infinite;
}
</style>
