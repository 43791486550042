<template>
  <div
    class="update-app"
    v-if="updateExists"
  >
    <div class="container">
      <div class="text">Une nouvelle version de Lowco est disponible</div>
      <div class="action">
        <span
          class="lowco-button"
          @click="refreshApp"
        > Mettre à jour </span>
      </div>
    </div>
  </div>
</template>

<script>
import update from '../../mixins/update';

export default {
  name: 'PWAUpdateApp',
  components: {},
  mixins: [update],
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";

.update-app {
  background-color: $soft-white;
  padding: 10px 0;
  font-size: 0;
  display: flex;

  .container {
    width: 100%;
    display: flex;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  width: calc(100% / 12 * 5);

  span {
    cursor: pointer;
    padding: 5px 20px;
  }
}

.text {
  display: flex;
  align-items: center;
  width: calc(100% / 12 * 7);
  font-size: 1.5rem;
}

.lowco-button {
  font-size: 1.4rem;
}
</style>
