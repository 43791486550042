import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';

import authenticationService from '@/services/authentication.service';

import useCompanyOffers from './useCompanyOffers';
import useCurrentCompany from './useCurrentCompany';
import routesConstants from '@/constants/routes.constants';

const useAuthentication = () => {
  const router = useRouter();
  
  const toast = useToast();
  const { t } = useI18n();

  const { clearCompany } = useCurrentCompany();
  const { clear: clearCompanyOffer } = useCompanyOffers();

  const signIn = async (credentials) => {
    try {
      await authenticationService.authenticate(credentials);

      router.push(routesConstants.HOME);
    } catch (err) {
      const [error] = err;
      
      toast.error(t(error));
    }
  };

  const signOut = async () => {
    try {
      await authenticationService.logout();
    } finally {
      clearCompany();
      clearCompanyOffer();

      router.push(routesConstants.SIGN_IN);
    }
  };

  return {
    signIn,
    signOut,
  };
};

export default useAuthentication;
